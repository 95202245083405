
const API_SERVER_HOST_URL = process.env.NODE_ENV === "production" 
    ? `${window.location.origin}`
    : `https://cors-anywhere.herokuapp.com/https://freightpeople-iot-api.australiasoutheast.cloudapp.azure.com`;

export const API_URL = `${API_SERVER_HOST_URL}/api/tracking/consignments/`;

// import { IConsignment } from "./interfaces";

// export const SAMPLE_CONNOTE_RESPONSE: IConsignment = {
//   id: 2108484,
//   customerName: "Contoso",
//   connoteNumber: "ABC123",
//   pickupDate: "2021-08-06T00:00:00",
//   customerReference: "GL-AAA",
//   serviceCode: "JET123",
//   serviceName: "Jet Couriers 3 Ton Hourly Hire",
//   pickupAddress: {
//     id: 697572,
//     code: '',
//     name: "Acme",
//     line1: "123 Acme Rd",
//     line2: "",
//     line3: '',
//     location: {
//       id: 3189,
//       locality: "MOUNT WAVERLEY",
//       state: "VIC",
//       postcode: "3149",
//       country: {
//         id: 36,
//         isO2: "AU",
//         isO3: "AUS",
//         name: "Australia",
//       },
//     },
//     contact: {
//       id: 0,
//       name: "Road Runner",
//       phone: "03 9123 1234",
//       mobile: '',
//       fax: '',
//       email: "roadrunner@acme.com",
//     },
//     isPostal: false,
//     isResidential: false,
//     isMineSite: false,
//     notes: "",
//   },
//   deliveryAddress: {
//     id: 868810,
//     code: '',
//     name: "AUSTEST",
//     line1: "2 Looney Toons Crt",
//     line2: '',
//     line3: '',
//     location: {
//       id: 1518,
//       locality: "RESERVOIR",
//       state: "VIC",
//       postcode: "3073",
//       country: {
//         id: 36,
//         isO2: "AU",
//         isO3: "AUS",
//         name: "Australia",
//       },
//     },
//     contact: {
//       id: 0,
//       name: "Bob",
//       phone: "9464 0000",
//       mobile: "",
//       fax: '',
//       email: "",
//     },
//     isPostal: false,
//     isResidential: false,
//     isMineSite: false,
//     notes: '',
//   },
//   specialInstructions: "",
//   totalItems: 1,
//   totalWeight: 650,
//   totalVolume: 2.4,
//   transportUnits: [
//     {
//       id: 0,
//       itemNo: 1,
//       code: '',
//       description: '',
//       transportUnitType: "Crate",
//       reference: "GL-AAA",
//       quantity: 0,
//       length: 150,
//       width: 100,
//       height: 160,
//       volume: 2.4,
//       weight: 650,
//       shortIdentifier: "CCC",
//       barcode: "",
//       packID: "",
//       itemInstructions: '',
//       hazardousMaterial: '',
//       assets: '',
//     },
//   ],
//   status: "Manifested",
//   events: [],
//   documents: [],
//   pickedUp: null,
//   onBoardForDelivery: null,
//   delivered: null,
//   hasPOD: false,
//   estimatedDelivery: null,
//   isOntime: true,
//   references: ["GL-AAA", "BBB", "CCC"],
// };
